import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { IoMdMenu } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { APP_CONSTANTS, ROUTE_CONSTANTS } from "constants/index";

import MainContent from "components/mainContent";
import Navbar from "components/navbar";

import { useLogoutMutation } from "features/auth/authApi";
import {
  logOut,
  selectCurrentRefreshToken,
  selectCurrentUser,
} from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

import { logoDigihealthWhite } from "assets/images/svg";
import { admin } from "assets/images/png";
import { upperCaseFirstChar } from "utils/upperCaseFirstChar";
import { fondationMed5, logoMinistreSante } from "assets/images/jpg";

export default function Index() {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [lastItemSelected, setLastItemSelected] = useState<string>(
    ROUTE_CONSTANTS.PATIENTS.PATH
  );
  const currentRefreshToken = useSelector(selectCurrentRefreshToken);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    initSideBar();
  }, [user]);

  const initSideBar = async () => {
    let lastItem = ROUTE_CONSTANTS.PATIENTS.PATH;
    const lastItemSelected_stored = await localStorage.getItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}lastItemSelected`
    );
    if (lastItemSelected_stored) {
      lastItem = lastItemSelected_stored;
    }
    setLastItemSelected(lastItem);
    navigate(lastItem);
  };

  const handleOnClickSideBar = (selectedPath: string) => {
    localStorage.setItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}lastItemSelected`,
      selectedPath
    );
    setLastItemSelected(selectedPath);
    setIsSideBarOpen(false);
  };

  const handleClickLogOut = async () => {
    try {
      const resLogOut = await logout({ refreshToken: currentRefreshToken });
      dispatch(logOut());
      navigate(ROUTE_CONSTANTS.LOGIN.PATH);
    } catch (e: any) {
      if (e.status.toString().chartAt(0) === "5")
        toast.error(
          "Problème de communication avec le serveur, Veuillez réessayer plus tard"
        );
    }
  };

  return (
    <>
      <div className="min-h-full overscroll-none z-20">
        <Transition.Root show={isSideBarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setIsSideBarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gradient-to-b from-[#164a94] to-cyan-500">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setIsSideBarOpen(false)}
                    >
                      <AiOutlineClose />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={logoDigihealthWhite}
                    alt="digihealth logo"
                  />
                </div>
                <Navbar
                  lastItemSelected={lastItemSelected}
                  handleOnClickSideBar={handleOnClickSideBar}
                  handleClickLogOut={handleClickLogOut}
                />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 z-20 ">
          {/* Sidebar component, swap this element with another sidebar  */}
          <div className="flex flex-col flex-grow bg-gradient-to-b from-[#164a94] to-cyan-500 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center justify-center flex-shrink-0 px-4">
              <img
                className="h-10 w-auto"
                src={logoDigihealthWhite}
                alt="digihealth logo"
              />
            </div>
            <Navbar
              lastItemSelected={lastItemSelected}
              handleOnClickSideBar={handleOnClickSideBar}
              handleClickLogOut={handleClickLogOut}
            />
          </div>
        </div>

        <div className="lg:pl-64 flex flex-col flex-1 z-20">
          <div className="relative z-10 flex-shrink-0 flex h-20  bg-white border-b border-gray-200 lg:border-none">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
              onClick={() => setIsSideBarOpen(true)}
            >
              <IoMdMenu />
              <span className="sr-only">Open sidebar</span>
            </button>
            {/* Menu bar */}
            <div className="flex-1 flex justify-end item-center  sm:px-6  lg:mx-auto lg:px-1">
              <div className="flex items-center md:ml-6">
                {/* Profile dropdown */}

                <img src={fondationMed5} className="h-[40px] mr-5" />
                <img src={logoMinistreSante} className="h-[40px] mr-10" />
                {/* <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs gap-3 bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {upperCaseFirstChar(user.firstName) +
                          " " +
                          upperCaseFirstChar(user.lastName)}
                      </span>

                      <img
                        className="h-14 w-14 rounded-full border-4 border-bleu"
                        src={admin}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                </Menu> */}
              </div>
            </div>
          </div>
          <main className="flex-1 min-h-full bg-[#E2F2F3]">
            <MainContent />
          </main>
        </div>
      </div>
    </>
  );
}
