import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SLICE } from "enums";
import { IRegionsState } from "interfaces";

/*region Slice*/

const getInitialeState = (): IRegionsState => {
  return { regions: [] };
};

const regionsSlice = createSlice({
  name: SLICE.REGION,
  initialState: getInitialeState(),
  reducers: {
    setRegions: (state, action) => {
      state.regions = action.payload.regions;
    },
    clearRegionsState: (state) => {
      state.regions = [];
    },
  },
});

export const { setRegions, clearRegionsState } = regionsSlice.actions;

export default regionsSlice.reducer;

export const selectCurrentRegions = (state: RootState) => state.regions;
