import { Dropdown, Space } from "antd";
import {
  AiFillFileExcel,
  AiFillFilePdf,
  AiFillPrinter,
  AiOutlineMenu,
} from "react-icons/ai";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import PrintSreenButton from "./PrintScreenPdf";
import ExportPDFButton from "./ExportPdfButton";
import ExcelExport from "./ExcelExport";
import PrintComponentButton from "./PrintComponentPdf";

interface IProps {
  fileName?: string;
  Component?: any;
  statisticsData?: any;
  rangeDate?: { from: Date; to: Date };
  showText?: boolean;
  componentId?: string;
}

const DropDownMenuExport = ({
  fileName,
  Component,
  statisticsData,
  rangeDate,
  showText = true,
  componentId,
}: IProps) => {
  return (
    <Dropdown
      className={
        showText
          ? "h-8 w-[100px] bg-gradient-to-r from-cyan-500 to-blue-500 rounded-md text-white mx-3 px-3 pt-1 hover:cursor-pointer"
          : ""
      }
      menu={{
        items: [
          {
            key: "1",
            icon: <AiFillPrinter size={20} />,
            label: !showText ? (
              <PrintComponentButton componentId="" />
            ) : (
              <PrintSreenButton />
            ),
          },
          {
            key: "2",
            label: (
              <ExportPDFButton Component={Component} fileName={fileName} />
            ),
            icon: <AiFillFilePdf size={20} />,

            disabled: true,
          },
          {
            key: "3",
            label: (
              <ExcelExport
                statisticsData={statisticsData}
                fileName={fileName}
                rangeDate={rangeDate}
              />
            ),
            icon: <AiFillFileExcel size={20} />,
            disabled: true,
          },
        ],
      }}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {showText && "Exporter"}
          {/* <DownOutlined /> */}
          <AiOutlineMenu />
        </Space>
      </a>
    </Dropdown>
  );
};

export default DropDownMenuExport;
