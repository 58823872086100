import React, { useState } from "react";

import { useGetStatisticsHooks } from "hooks/useGetStatistics";
import { evolution } from "utils";
import { IStatisticsClinicalPerformance } from "interfaces";
import ClinicalPerformancePlainText from "./ClinicalPerformancePlainText";
import { restructStatisticsClinicalPerformance } from "utils/restructStatistics";

/**
 * Clinical performance statistics, container component : fetch, restruct and deal with data
 * Third page of the dashboard
 * @returns
 */

export default function ClinicalPerformanceContainer() {
  /*state of statistics*/
  const [statistics, setStatistic] = useState<
    IStatisticsClinicalPerformance | undefined
  >(undefined);
  /* hook */
  const getStatistics = useGetStatisticsHooks();

  /* handle change of filter by getting new data from server */
  const handleChanges = async (filter: any) => {
    /* getStatistics(filter, 3) 3 indicates that we want statistics for clinical performance*/
    const resStatistics: any = await getStatistics(filter, 3);

    if (resStatistics.data) {
      /* restruct data of count medical act*/
      const restructedData: IStatisticsClinicalPerformance =
        restructStatisticsClinicalPerformance(resStatistics.data);
      setStatistic(restructedData);
    }
  };

  return (
    <ClinicalPerformancePlainText
      statistics={statistics}
      handleChanges={handleChanges}
    />
  );
}
