import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SLICE } from "enums";
import { IProvincesState } from "interfaces";

/*province Slice*/

const getInitialeState = (): IProvincesState => {
  return { provinces: [] };
};

const provincesSlice = createSlice({
  name: SLICE.PROVINCE,
  initialState: getInitialeState(),
  reducers: {
    setProvinces: (state, action) => {
      state.provinces = action.payload.provinces;
    },
    clearProvincesState: (state) => {
      state.provinces = [];
    },
  },
});

export const { setProvinces, clearProvincesState } = provincesSlice.actions;

export default provincesSlice.reducer;
export const selectCurrentProvinces = (state: RootState) => state.provinces;
