import { ROUTE_CONSTANTS } from "constants/index";
import { Link } from "react-router-dom";

import {
  administrationIcon,
  pharmacieIcon,
  performanceIcon,
  telemedecineIcon,
  facturationIcon2,
  logoutIcon,
  logoMediot,
} from "assets/images/svg";

const menuItems = [
  {
    path: ROUTE_CONSTANTS.PATIENTS.PATH,
    title: ROUTE_CONSTANTS.PATIENTS.title,
    icon: administrationIcon,
  },
  {
    path: ROUTE_CONSTANTS.MEDICATION_AND_PHARMA_MANAGEMENT.PATH,
    title: ROUTE_CONSTANTS.MEDICATION_AND_PHARMA_MANAGEMENT.title,
    icon: pharmacieIcon,
  },
  {
    path: ROUTE_CONSTANTS.CLINICAL_PERFORMANCE.PATH,
    title: ROUTE_CONSTANTS.CLINICAL_PERFORMANCE.title,
    icon: performanceIcon,
  },
  {
    path: ROUTE_CONSTANTS.TELEMEDCINE.PATH,
    title: ROUTE_CONSTANTS.TELEMEDCINE.title,
    icon: telemedecineIcon,
  },
  {
    path: ROUTE_CONSTANTS.BILLING.PATH,
    title: ROUTE_CONSTANTS.BILLING.title,
    icon: facturationIcon2,
  },
];

interface IProps {
  lastItemSelected: string;
  handleOnClickSideBar: (arg: string) => void;
  handleClickLogOut: () => void;
}

export default function Index({
  lastItemSelected,
  handleOnClickSideBar,
  handleClickLogOut,
}: IProps) {
  return (
    <nav
      className="mt-10 flex-1 flex flex-col divide-y w-full overflow-y-auto"
      aria-label="Sidebar"
    >
      <div>
        {menuItems.map((item, index) => (
          <div key={item.path}>
            <Link to={item.path} className="flex items-start h-20">
              <button
                className={` ${
                  lastItemSelected === item.path
                    ? "bg-red-600 rounded-tr-3xl rounded-br-lg w-[3rem] h-[4rem] mr-[-10px] z-50 shadow-2xl	"
                    : "text-left bg-transparent text-white rounded-tr-3xl rounded-br-lg w-[3rem] h-[4rem]"
                }`}
                onClick={() => handleOnClickSideBar(item.path)}
              >
                <img src={item.icon} alt="" />
              </button>
              <button
                className={`${
                  lastItemSelected === item.path
                    ? "text-left text-white bg-[#0a376c] rounded-tl-3xl rounded-bl-lg z-10  h-[4rem] w-[100%] text-[16px]  pl-6 shadow-2xl"
                    : "text-left bg-transparent text-white rounded-tl-3xl rounded-bl-lg  h-[4rem] w-[100%] text-[16px]  pl-6"
                }`}
                onClick={() => handleOnClickSideBar(item.path)}
              >
                {item.title}
              </button>
            </Link>
            <div className=""></div>
            <div className="flex items-end justify-end">
              <hr className="w-[80%]  h-0.5 "></hr>
            </div>
          </div>
        ))}
        <div className="flex items-start h-20" onClick={handleClickLogOut}>
          <button
            className={
              "text-left bg-transparent text-white rounded-tr-3xl rounded-br-lg w-auto h-[4rem]"
            }
          >
            <img src={logoutIcon} alt="" />
          </button>
          <button
            className={
              "text-left bg-transparent text-white rounded-tl-3xl rounded-bl-lg  h-[4rem] w-[100%] text-lg ml-[-7px] pl-6"
            }
          >
            Déconnexion
          </button>
        </div>
        <div className=""></div>
        <div className="flex items-end justify-end">
          <hr className="w-[80%]  h-0.5"></hr>
        </div>
        <div className="flex justify-center">
          <img className="h-24 w-auto" src={logoMediot} alt="Workflow" />
        </div>
      </div>
    </nav>
  );
}
