import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface Iprops {
  nameSerie: string;
  data: number[] | string[];
  categories: string[];
}

export default function NumberOfClinicalProcedures({
  nameSerie,
  data,
  categories,
}: Iprops) {
  const state: any = {
    series: [
      {
        name: nameSerie,
        data,
      },
    ],
    legend: {
      show: true,
    },

    options: {
      zoom: false,
      chart: {
        type: "area",
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#8fc689"],

      xaxis: {
        categories,
      },
    },
    responsive: [
      {
        breakpoint: 1151,
        options: {
          width: "100%",
        },
      },
    ],
  };
  const [chartWidth, setChartWidth] = useState<number>();
  const [windowSize, setWindowSize] = useState<any>("");
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    windowSize > 700 ? setChartWidth(620) : setChartWidth(320);
  }, [windowSize]);

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="area"
        height={"50%"}
        width={"100%"}
      />
    </>
  );
}
