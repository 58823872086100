import React from "react";
import Chart from "react-apexcharts";

import { repartitionIcon } from "assets/images/svg";
import NoData from "components/common/noData";
import TitleChart from "components/common/TitleChart";

interface Iprops {
  title: string;
  data: number[];
}

export default function DistributionByAge({ title, data }: Iprops) {
  const state: any = {
    series: data,
    options: {
      chart: {
        minWidth: 380,
        maxWidth: 1200,
        type: "pie",
      },
      labels: [
        "0 à 6 ans",
        "7 à 14 ans",
        "15 à 24 ans",
        "24 à 64 ans",
        "plus de 65 ans",
      ],
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10,
          },
        },
      },

      colors: [
        "#0B2349",
        "#18B6C0",
        "#546E7A",
        "#E29500",
        "#4482BE",
        "#59AFE6",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              toolbar: {
                show: false,
              },
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="flex w-auto flex-col justify-between bg-white rounded-lg p-3 m-1 max-h-auto h-auto pb-8">
        <TitleChart title={title} img={repartitionIcon} />
        {data.reduce((acc, curr) => acc + curr) === 0 && <NoData />}
        <Chart
          options={state.options}
          series={state.series}
          type="pie"
          height={250}
          width={320}
        />
      </div>
    </>
  );
}
