export const getLengthOfArrayExcludingNullValues = (arr: number[]) => {
  return arr.filter((val) => val !== 0 && val != null).length;
};

export const avgOfArray = (arr: number[]) => {
  return arr.filter((item: number) => item !== 0).length === 0
    ? 0
    : Math.round(
        (arr.reduce((acc: number, curr: number) => acc + curr) /
          getLengthOfArrayExcludingNullValues(arr)) *
          100
      ) / 100;
};

export const sumItemsArray = (arr: number[]) => {
  if (getLengthOfArrayExcludingNullValues(arr) === 0) return 0;
  else return arr.reduce((acc: number, curr: number) => acc + curr);
};

export const replaceNullByZero = (arr: any[]) => {
  return arr.map((item: any) => item || 0);
};
