import React, { useState } from "react";

interface IProps {
  title: string;
  img?: any;
  total?: string | number;
}

function TitleChart({ title, img, total }: IProps) {
  return (
    <>
      <div className="col-span-3 flex items-start  justify-between w-auto">
        <p className="text-[#25265E] text-sm font-semibold">{title}</p>
        {img && (
          <img src={img} alt="" className="w-25 h-12 mt-0 m-2 self-end" />
        )}
        {total && (
          <p className="text-[#0DA8AE] text-[50px] font-bold "> {total}</p>
        )}
      </div>
    </>
  );
}

export default TitleChart;
