import { apiSlice } from "app/api/apiSlice";

/*unit: center,  api, get unit by regionId, provinceId or get all units*/
const unitsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query<any, { regionId: number; provinceId: number }>({
      query: ({ regionId, provinceId }) => {
        let q = "/center";
        if (provinceId) {
          const query = JSON.stringify({ city: { provinceId } });
          q += `?where=${query}`;
        } else if (regionId) {
          const query = JSON.stringify({
            city: { province: { regionId } },
          });
          q += `?where=${query}`;
        }
        return q;
      },
    }),
  }),
});
export const { useGetUnitsQuery } = unitsApi;

export default unitsApi;
