import { createSlice } from "@reduxjs/toolkit";
import { SLICE } from "enums/store";
import { RootState } from "app/store";
import { APP_CONSTANTS } from "constants/index";
import { IAuthState } from "interfaces";

const getInitialState = (): IAuthState => {
  //to check with Zakaria and Moad, the best way to secure tokens
  const storedToken = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
  );

  const storedUserInfos: any = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`
  );
  const storedRefreshToken = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
  );
  const initialState: IAuthState = {
    accessToken: storedToken,
    refreshToken: storedRefreshToken,
    user: storedUserInfos ? JSON.parse(storedUserInfos) : storedUserInfos,
    isConnected: false,
  };

  return initialState;
};

const authSlice = createSlice({
  name: SLICE.AUTH,
  initialState: getInitialState(),
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isConnected = true;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`,
        JSON.stringify(action.payload.user)
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
        accessToken
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
        refreshToken
      );
    },
    logOut: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isConnected = false;
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`);
      localStorage.removeItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
      );
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentAccessToken = (state: RootState) =>
  state.auth.accessToken;
export const selectCurrentRefreshToken = (state: RootState) =>
  state.auth.refreshToken;
