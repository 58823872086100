function NoData() {
  return (
    <div className="flex justify-start items-start m-0">
      <h3 className="text-[#0DA8AE] text-3xl font-bold mt-5 flex gap-2 items-center w-auto">
        <span className="text-sm">Aucune Data</span>
      </h3>
    </div>
  );
}

export default NoData;
