import React, { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";

import Loading from "components/common/loading";
import {
  atIcon,
  lockIcon,
  logoDigihealth,
  logoMediot,
} from "assets/images/svg";
import { bgLogin2 } from "assets/images/png";

/**
 * Login, plain text component: get credentials from inputs
 * First page of the dashboard
 * @returns
 */

interface IProps {
  setUserName: (args: any) => void;
  setPassword: (args: any) => void;
  handleLogin: () => Promise<void>;
  isLoading: boolean;
}

export default function LoginPlainText({
  setUserName,
  setPassword,
  handleLogin,
  isLoading,
}: IProps) {
  const userNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userNameRef.current?.focus();
  }, []);

  return (
    <div
      className="w-[full] h-[100vh] flex items-center justify-center self-center "
      style={{
        backgroundImage: `url(${bgLogin2})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100vw 100vh",
      }}
    >
      <div className="mx-auto w-full max-w-sm lg:w-96 items-center justify-center ">
        <div className="flex justify-center">
          <img className="h-17 w-auto" src={logoDigihealth} alt="Workflow" />
        </div>
        <div className="mt-12 bg-gradient-to-t from-[#0B2349]/75 to-[#08A5AB]/75 px-20 py-10 rounded-3xl shadow-2xl ">
          <div className="mt-6 opacity-100">
            <form className="flex flex-col justify-center items-center space-y-6 ">
              <div className="justify-center items-center">
                <div className="mt-1 w-64 flex justify-center items-center bg-white rounded-lg px-3">
                  <img src={atIcon} alt="" className="z-20" />
                  <input
                    ref={userNameRef}
                    onChange={(e) => setUserName(e.target.value)}
                    id="userName"
                    name="userName"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="space-y-1 w-64">
                <div className="mt-1 flex justify-center items-center bg-white rounded-lg px-3">
                  <img src={lockIcon} className=" z-20" alt="" />
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 rounded-rt-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => {
                    !isLoading && handleLogin();
                  }}
                  className="w-64 flex justify-center py-2 px-4  border border-transparent rounded-md shadow-sm text-sm uppercase font-medium text-white bg-gradient-to-b from-[#0A536F] to-[#000B33] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {!isLoading ? "SE CONNECTER" : <Loading type="default" />}
                </button>
              </div>
            </form>
            <Toaster position="top-center" />
          </div>
        </div>
        <div className="flex justify-center">
          <img className="h-36 w-auto" src={logoMediot} alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
