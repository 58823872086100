import NoData from "components/common/noData";
import React from "react";
import Chart from "react-apexcharts";

interface IProps {
  nameSerie: string;
  data: { count: number; name: string }[];
}

export default function TypeOfSpecialties({ data }: IProps) {
  const state: any = {
    series: data.map((e) => e.count),
    options: {
      chart: {
        minWidth: 380,
        maxWidth: 1200,
        type: "pie",
      },
      labels: data.map((e) => e.name),
      legend: {
        position: "bottom",
      },
      colors: [
        "#25265E",
        "#3D98D1",
        "#28C0A2",
        "#2AED45",
        "#627D05",
        "#7A31F7",
        "#C02EE4",
        "#8ED2F6",
        "#DCB85A",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              toolbar: {
                show: false,
              },
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <div className="flex justify-start items-start m-0">
      {(data.map((e) => e.count).length === 0 ||
        (data.map((e) => e.count).length > 0 &&
          data
            .map((e) => e.count)
            .reduce((acc: number, curr: number) => acc + curr) === 0)) && (
        <NoData />
      )}
      <Chart
        options={state.options}
        series={state.series}
        type="donut"
        height={320}
        width={480}
      />
    </div>
  );
}
