import React from "react";
import * as XLSX from "xlsx";

interface IProps {
  statisticsData?: any;
  fileName?: string | undefined;
  rangeDate?: { from: Date; to: Date };
}
const ExcelExport = ({ statisticsData, fileName, rangeDate }: IProps) => {
  console.log(statisticsData, fileName, rangeDate);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(statisticsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `${
        fileName + (rangeDate ? rangeDate.from + " - " + rangeDate.to : "") ||
        "visiostation statistics"
      }.xlsx`
    );
  };

  return (
    <div
    // onClick={exportToExcel}
    // className="h-8 w-20 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-md	text-white mx-3 px-3"
    >
      Excel
    </div>
  );
};

export default ExcelExport;
