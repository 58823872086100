import React from "react";
import Chart from "react-apexcharts";

import { womanIcon, manIcon } from "assets/images/svg";
import NoData from "components/common/noData";
import TitleChart from "components/common/TitleChart";

interface Iprops {
  femaleCount: number;
  maleCount: number;
}

export default function DistributionBySex({ femaleCount, maleCount }: Iprops) {
  const state: any = {
    series: [femaleCount, maleCount],
    options: {
      chart: {
        minWidth: 380,
        maxWidth: 1200,
        type: "pie",
      },
      labels: ["Femme", "Homme"],
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -15,
          },
        },
      },
      legend: {
        show: false,
      },
      colors: ["#983333", "#59AFE6"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 300,
              toolbar: {
                show: false,
              },
            },
            legend: {
              show: false,
              position: "right",
            },
          },
        },
      ],
    },
  };

  const calculatePercent = (value: number) => {
    if (femaleCount === 0 && maleCount === 0) return 0;
    return Math.round((value / (femaleCount + maleCount)) * 100);
  };

  return (
    <div className="flex flex-col bg-white rounded-lg px-4 m-1 h-auto py-3.5 ">
      <TitleChart title="RÉPARTITION PAR SEXE" />
      {maleCount + femaleCount === 0 && <NoData />}
      <div className="grid grid-cols-2 mb-14">
        <div>
          <Chart
            options={state.options}
            series={
              femaleCount && maleCount ? [femaleCount, maleCount] : [0, 0]
            }
            type="pie"
            height={170}
            width={280}
            className="ml-[-5rem]"
          />
        </div>
        <div className="flex flex-col align-top items-end gap-2 justify-center mr-5">
          <div className="flex">
            <img src={womanIcon} alt="" className="h-10 w-5" />
            <p className="flex gap-7 px-5 items-center bg-gradient-to-r from-[#ffebeb00] to-[#FFEBEB] rounded-tr-lg rounded-br-lg p-2">
              <span className="text-[#AF5050] font-bold text-xl">
                {calculatePercent(femaleCount)}%
              </span>
              Femme
            </p>
          </div>

          <div className="flex ">
            <img src={manIcon} alt="" className="h-10 w-5" />
            <p className="flex gap-7 px-5 items-center bg-gradient-to-r from-[#e3f4ff00] to-[#E3F4FF] rounded-tr-lg rounded-br-lg p-2">
              <span className="text-[#3F7CA2] font-bold text-xl">
                {calculatePercent(maleCount)}%
              </span>{" "}
              Homme
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
