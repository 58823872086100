import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";

import TotalPatientsChart from "components/charts/TotalPatientsTreated";
import NumberOfNewPatientsChart from "components/charts/NumberOfNewPatients";
import TotalBenefitsChart from "components/charts/TotalBenefits";
import AverageDurationChart from "components/charts/AverageDurationOfConsultations";
import DistributionByAgeChart from "components/charts/DistributionByAge";
import DistributionBySexChart from "components/charts/DistributionBySex";
import NumberOfTeleExpertisesChart from "components/charts/NumberOfTeleExpertisesCarriedOut";
import SEOReasonsChart from "components/charts/SEOReasons";
import TitleChart from "./TitleChart";
import { chart } from "enums/index";
import DropDownMenuExport from "components/exportButton/DropDownMenu";

interface IDynamicCardProps {
  title: string;
  withCount?: boolean;
  subTitle?: string;
  subCount?: string | number;
  count?: number | string;
  logo?: any;
  typeChart: any;
  percent?: any;
  progress?: boolean;
  secondeIcon?: any;
  unité?: any;
  nameSerie: string;
  data: number[] | string[];
  categories: string[];
}

export const DynamicCard = ({
  title,
  withCount = true,
  count,
  subTitle,
  subCount,
  logo,
  typeChart,
  secondeIcon,
  unité,
  nameSerie,
  data,
  categories,
}: IDynamicCardProps) => {
  return (
    <div
      id="totalTakenCare"
      className="flex w-auto flex-col justify-between bg-white rounded-lg px-3 py-5 m-1 max-h-auto h-auto"
    >
      <div className="absolute self-end	-mt-4 -mr-1">
        <DropDownMenuExport showText={false} />
      </div>
      <TitleChart title={title} img={logo} />
      <div className="flex justify-start items-end">
        <div className="w-auto">
          <div className="flex gap-3">
            {withCount && (
              <>
                <img
                  src={secondeIcon}
                  alt=""
                  className="w-25 h-9 mt-0 m-2 self-end"
                />
                <h3 className="text-[#0DA8AE] text-4xl font-bold -mt-5 flex gap-2 items-center w-auto">
                  {count}
                  <span className="text-sm">{unité}</span>{" "}
                </h3>
              </>
            )}
            {subTitle && (
              <div className="absolute flex-col mt-[60px] ml-5 z-40">
                <h3 className="text-[#154692] text-2xl font-bold mt-5 flex gap-2 items-center w-auto">
                  {subCount}
                  <span className="text-sm">{unité}</span>{" "}
                </h3>
                <p className="text-[#154692] text-lx font-bold">{subTitle}</p>
              </div>
            )}
          </div>
          {typeChart === chart.TOTAL_PATIENTS ? (
            <TotalPatientsChart
              nameSerie={nameSerie}
              data={data}
              categories={categories}
            />
          ) : typeChart === chart.NEW_PATIENTS ? (
            <NumberOfNewPatientsChart
              nameSerie={nameSerie}
              data={data}
              categories={categories}
            />
          ) : typeChart === chart.TOTAL_BENEFITS ? (
            <>
              <TotalBenefitsChart
                nameSerie={nameSerie}
                data={data}
                categories={categories}
              />
            </>
          ) : typeChart === chart.AVERAGE_DURATION ? (
            <AverageDurationChart
              nameSerie={nameSerie}
              data={data}
              categories={categories}
            />
          ) : typeChart === chart.TELE_EXPERTISES ? (
            <NumberOfTeleExpertisesChart
              nameSerie={nameSerie}
              data={data}
              categories={categories}
            />
          ) : typeChart === chart.SEO_REASONS ? (
            <SEOReasonsChart
              nameSerie={nameSerie}
              data={data}
              categories={categories}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicCard;
