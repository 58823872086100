import {
  IStatisticsClinicalPerformance,
  IStatisticsMedicalManagment,
  IStatisticsPatients,
  IStatisticsPatientsDataServer,
} from "interfaces";
import { evolution, toSecond } from "utils";

import { avgOfArray, replaceNullByZero, sumItemsArray } from "./array.utils";
import { IStatisticsMedicalManagmentDataServer } from "interfaces/statisticsMedicationsManagment.interface";
import { IStatisticsClinicalPerformanceDataServer } from "interfaces/statisticsClinicalPerformance.interface";
import { IStatisticsBillingDataServer } from "interfaces/statisticsBilling.interface";
import moment from "moment";

/**
 * restruct data
 */

export function restructStatisticsPatientsManagment(
  statistics: IStatisticsPatientsDataServer
) {
  const totalTakenCare = {
    cumuleFromStart: statistics.totalTakenCare.cumuleFromStart,
    perday: {
      data: statistics.totalTakenCare.perday.data,
      dates: statistics?.totalTakenCare?.perday?.dates?.map((d) =>
        moment(d).format("DD-MM-YYYY")
      ),
    },
  };
  const newPatient = {
    avg_rate: avgOfArray(statistics.totalNewPatient.data),
    perDay: {
      data: statistics.totalNewPatient.data,
      dates: statistics.totalNewPatient.dates.map((d) =>
        moment(d).format("DD-MM-YYYY")
      ),
    },
  };

  const ratePatientTransfert = statistics.transfertCount.data.map(
    (item: any, index: any) => {
      if (item === 0 || statistics.totalConsultation.data[index] === 0)
        return 0;
      else
        return (
          Math.round(
            (item / statistics.totalConsultation.data[index]) * 10000
          ) / 100
        );
    }
  );

  const patientTransfert = {
    avg_rate: avgOfArray(ratePatientTransfert),
    perDay: {
      data: ratePatientTransfert,
      dates: statistics.transfertCount.dates.map((d) =>
        moment(d).format("DD-MM-YYYY")
      ),
    },
  };

  const totalPrestation = {
    total: sumItemsArray(statistics.totalConsultation.data),
    perDay: {
      data: statistics.totalConsultation.data,
      dates: statistics.totalConsultation.dates.map((d) =>
        moment(d).format("DD-MM-YYYY")
      ),
    },
  };

  const restructed_data: IStatisticsPatients = {
    totalTakenCare,
    newPatient,
    totalPrestation,
    totalAdmissions: statistics.totalAdmissions,
    avgTimeConsultation: statistics.avgTimeConsultation,
    agesSeries: replaceNullByZero(statistics.agesSeries),
    genderCount: {
      femaleCount: statistics.genderCount.femaleCount || 0,
      maleCount: statistics.genderCount.maleCount || 0,
    },
    patientTransfert,
    countMotifsTransfert: statistics.countMotifsTransfert,
  };

  return restructed_data;
}

export function restructStatisticsMedicationManagment(
  statistics: IStatisticsMedicalManagmentDataServer
) {
  console.log("statistics", statistics);

  const restruct_Statistics: IStatisticsMedicalManagment = {
    medicationsPrescriptionRate: {
      avg: avgOfArray(statistics.medicationsPrescriptionRate.data),
      perDay: {
        data: statistics.medicationsPrescriptionRate.data,
        dates: statistics.medicationsPrescriptionRate.dates.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    dciOrder: statistics.dciOrder,
    antibioticCount: {
      avg: avgOfArray(
        statistics.antibioticCount.data.map((e: string) => parseFloat(e))
      ),
      perDay: {
        data: statistics.antibioticCount.data.map((e: string) => parseFloat(e)),
        dates: statistics.antibioticCount.dates.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    stockMedication: {
      avg:
        Math.round(
          avgOfArray(
            statistics.antibioticCount.data.map((e: string) => parseFloat(e))
          ) *
            0.6 *
            100
        ) / 100,
      perDay: {
        data: statistics.antibioticCount.data.map(
          (e: string) => Math.round(Number(e) * 60) / 100
        ),
        dates: statistics.antibioticCount.dates.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
  };
  console.log("stockMedication", restruct_Statistics.stockMedication);
  return restruct_Statistics;
}

export function restructStatisticsClinicalPerformance(
  statistics: IStatisticsClinicalPerformanceDataServer
) {
  const restructedData: IStatisticsClinicalPerformance = {
    groupByPathology: statistics.groupByPathology,
    countMedicalAct: {
      total: sumItemsArray(statistics.countMedicalAct.perDay.data),
      perDay: {
        data: statistics.countMedicalAct.perDay.data,
        dates: statistics.countMedicalAct.perDay.dates.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    diabeteScreening: {
      total: sumItemsArray(statistics.diabeteScreening),
      perDay: {
        data: statistics.diabeteScreening,
        dates: statistics.datesScreening.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    htaScreening: {
      total: sumItemsArray(statistics.htaScreening),
      perDay: {
        data: statistics.htaScreening,
        dates: statistics.datesScreening.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    breastCancerScreening: {
      total: sumItemsArray(statistics.breastCancerScreening),
      perDay: {
        data: statistics.breastCancerScreening,
        dates: statistics.datesScreening.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
    cervicalCancerScreening: {
      total: sumItemsArray(statistics.cervicalCancerScreening),
      perDay: {
        data: statistics.cervicalCancerScreening,
        dates: statistics.datesScreening.map((d) =>
          moment(d).format("DD-MM-YYYY")
        ),
      },
    },
  };
  return restructedData;
}

export function restructStatisticsTelemedcine(statistics: any) {
  //restruct data of AVG time telexpertise
  const avgTimeTelexpertise_data = statistics.data.avgTimeTelexpertise;

  const avgTimeTelexpertise_restructed = {
    ...statistics.data.avgTimeTelexpertise,
    evolution: evolution(
      toSecond(avgTimeTelexpertise_data.avg[0].avg_consultations),
      toSecond(avgTimeTelexpertise_data.previousWeek[0].avg_consultations)
    ),
  };
  return { avgTimeTelexpertise_restructed };
}

export function restructStatisticsBillings(
  statistics: IStatisticsBillingDataServer
) {
  const dataEvolution: { name: string; data: number[] }[] = [];
  Object.keys(statistics.dataEvolution.data).map((e: string) => {
    dataEvolution.push({
      name: e,
      data: statistics.dataEvolution.data[e].map(
        (item: { total: string; totalPrice: string | number }) =>
          Number(item.total)
      ),
    });
  });
  const dates =
    statistics.dataEvolution.sortedDates.map((d) =>
      moment(d).format("DD-MM-YYYY")
    ) || [];
  const restructedData = {
    actDataBilling: statistics.actData
      .filter((item, index) => index < 11)
      .map(
        (item: {
          category: string;
          total: string | number;
          total_price: number;
        }) => {
          return {
            category: item.category,
            total_price:
              item.total_price == null ? "0 DHs" : `${item.total_price} DHs`,
          };
        }
      ),
    actData: statistics.actData
      .filter((item, index) => index < 11)
      .map(
        (item: {
          category: string;
          total: string | number;
          total_price: number;
        }) => {
          return {
            category: item.category,
            total:
              item.total === null
                ? 0
                : typeof item.total === "string"
                ? Number(item.total)
                : item.total,
          };
        }
      ),
    dataEvolution: { statistics: dataEvolution, dates },
  };

  console.log("restructedData.dataEvolution", restructedData.dataEvolution);
  return restructedData;
}
