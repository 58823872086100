import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAccessToken } from "features/auth/authSlice";
import { ROUTE_CONSTANTS } from "constants/index";

/*Secure routes that required an authentification*/
const RequiredAuth = () => {
  const accessToken = useSelector(selectCurrentAccessToken);
  const location = useLocation();
  console.log("accessToken protected auth", accessToken);

  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={ROUTE_CONSTANTS.LOGIN.PATH}
      state={{ from: location }}
      replace
    />
  );
};
export default RequiredAuth;
