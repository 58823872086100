import React from "react";

const PrintSreenButton = () => {
  const handlePrint = () => {
    window.print();
  };

  return <button onClick={() => handlePrint()}>Imprimer</button>;
};

export default PrintSreenButton;
