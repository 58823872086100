import React from "react";
import AverageDurationOfTeleexpertiseSession from "components/charts/AverageDurationOfTeleexpertise";
import TypeOfSpecialities from "components/charts/TypeOfSpecialties";
import HeaderLayout from "components/contentLayout";
import FilterBar from "components/filterBar";

import { teleIcon1, teleBg1, teleBg4 } from "assets/images/png";
import { clockIcon2, doctorMaleIcon } from "assets/images/svg";
import Loading from "components/common/loading";
import Evolution from "components/common/evolution";
import { margeTimeSelector } from "utils";
import { IStatisticsTeleMedcine } from "interfaces";

/**
 * Telemedicine statistics, plain text component: display data only
 * Fourth page of the dashboard
 * @returns
 */

interface IProps {
  statistics: IStatisticsTeleMedcine | undefined;
  handleChanges: (args: any) => Promise<void>;
}

export default function TelemedicinePlainText({
  statistics,
  handleChanges,
}: IProps) {
  return (
    <div className="bg-[#E2F2F3]">
      <div>
        <HeaderLayout
          title={"Télémédecine"}
          subTitle={"Télémédecine"}
          logo={teleIcon1}
        />
        <FilterBar handleChanges={handleChanges} />
      </div>
      {statistics ? (
        <div
          className=" mx-1 gap-6 p-3 bg-[#E2F2F3]"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(min(30rem,100%),1fr))",
          }}
        >
          <div
            className="block  flex-col gap-4 p-4 rounded-lg w-auto h-auto bg-white"
            style={{
              backgroundImage: `url(${teleBg1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "top right",
            }}
          >
            <p className="text-[#25265E] font-semibold text-sm my-2">
              TYPE DE SPÉCIALITÉS CONSULTÉES EN TÉLÉ-EXPERTISE.
            </p>

            <TypeOfSpecialities
              nameSerie="Spécialités"
              data={statistics.countBySpecialities}
            />
          </div>

          <div
            className="block   h-auto flex-col gap-10 p-5 rounded-lg  bg-white"
            style={{
              backgroundImage: `url(${teleBg4})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
          >
            <div>
              <p className="text-[#25265E] px-2 font-semibold text-sm my-2">
                DURÉE MOYENNE D'UNE SESSION DE TÉLÉ-EXPERTISE
              </p>

              <div className="flex flex-row justify-start items-center gap-5 my-5 px-2">
                <img src={clockIcon2} alt="" className="h-16 w-16" />
                <p className="text-[#0DA8AE] text-3xl font-bold">
                  {statistics.avg}
                </p>
              </div>
            </div>
            <div className="w-full h-[2px] bg-[#5CBDC1]" />
            <div>
              <p className="text-[#25265E] px-2 font-semibold text-sm my-2">
                TAUX DE TÉLÉEXPERTISE
              </p>

              <div className="flex flex-row justify-start items-center gap-5 my-5 px-2">
                <img src={doctorMaleIcon} alt="" className="h-16 w-16" />
                <p className="text-[#0DA8AE] text-3xl font-bold">
                  {statistics?.rate?.toFixed(2)}%
                </p>
              </div>
            </div>
            <div className="w-full h-[2px] bg-[#5CBDC1]" />
            <div>
              <p className="text-[#25265E] px-2 font-semibold text-sm my-2">
                NOMBRE DE TÉLÉEXPERTISE
              </p>

              <div className="flex flex-row justify-start items-center gap-5 my-5 px-2">
                <img src={doctorMaleIcon} alt="" className="h-16 w-16" />
                <p className="text-[#0DA8AE] text-3xl font-bold">
                  {statistics.total}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex col justify-center  bg-sky-500/50 p-20 pt-60">
          <Loading type="spin" size="large" />
        </div>
      )}
    </div>
  );
}
