import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SLICE } from "enums";
import { IUnitsState } from "interfaces";

/*unit Slice */

const getInitialState = (): IUnitsState => {
  return { units: [] };
};

const unitsSlice = createSlice({
  name: SLICE.UNIT,
  initialState: getInitialState(),
  reducers: {
    setUnits: (state, action) => {
      state.units = action.payload.units;
    },
    clearUnitsState: (state) => {
      state.units = [];
    },
  },
});

export const { setUnits, clearUnitsState } = unitsSlice.actions;

export default unitsSlice.reducer;

export const selectCurrentUnits = (state: RootState) => state.units;
