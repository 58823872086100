import { apiSlice } from "app/api/apiSlice";
import { IQuery } from "interfaces";

/*statistics api*/
export const statisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getstatistics: builder.query<any, IQuery>({
      query: (query: IQuery) => {
        if (query.q)
          return `/dashboard/details/${query.id}?from=${query.from}&to=${query.to}&${query.q}`;
        else
          return `/dashboard/details/${query.id}?from=${query.from}&to=${query.to}`;
      },
    }),
  }),
});

export const { useGetstatisticsQuery, useLazyGetstatisticsQuery } =
  statisticsApiSlice;
