import React, { useState } from "react";

import { useGetStatisticsHooks } from "hooks/useGetStatistics";
import { IStatisticsTeleMedcine } from "interfaces";
import TelemedicinePlainText from "./TelemedecinePlainText";
import { restructStatisticsTelemedcine } from "utils/restructStatistics";

/**
 * Telemedicine statistics, container component: fetch, restruct and deal with data
 * Fourth page of the dashboard
 * @returns
 */

export default function TelemedicineContainer() {
  /*state*/
  const [statistics, setStatistic] = useState<IStatisticsTeleMedcine>();
  /*hook*/
  const getStatistics = useGetStatisticsHooks();

  /* handle change of filter by getting new data from server */
  const handleChanges = async (filter: any) => {
    /* getStatistics(filter, 4) 4 indicates that we want statistics for telemedcine*/
    const resStatistics: any = await getStatistics(filter, 4);
    setStatistic(resStatistics.data);
  };

  return (
    <TelemedicinePlainText
      statistics={statistics}
      handleChanges={handleChanges}
    />
  );
}
