import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PatientsContainer from "pages/patientsAdministration/PatientsContainer";
import { store } from "app/store";
import { Provider } from "react-redux";
interface IProps {
  Component: any;
  fileName: string | undefined;
}

const ExportPDFButton = ({ Component, fileName }: IProps) => {
  console.log("Component", Component);
  //   const exportToPDF = () => {
  //     const MyDocument = (
  //       <Document>
  //         <Page>
  //           <Component />
  //         </Page>
  //       </Document>
  //     );

  //     // Create PDF blob
  //     const blob = new Blob([MyDocument], { type: "application/pdf" });

  //     // Create download link
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = `${fileName}.pdf`;
  //     link.click();
  //   };

  const MyComponentDocument = () => (
    <Document>
      <Page>
        {/* <Provider store={store}>
          <PatientsContainer />
        </Provider> */}
      </Page>
    </Document>
  );

  return Component && fileName ? (
    <div>
      {/* <PDFDownloadLink
        document={MyComponentDocument()}
        fileName={`${"fileName"}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download PDF"
        }
      </PDFDownloadLink> */}
      Pdf
    </div>
  ) : (
    <div />
  );
};

export default ExportPDFButton;
