import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface IProps {
  nameSerie: string;
  data: number[];
  categories: string[];
}

export default function IncidenceAndPrevalenceOfPathologies({
  nameSerie,
  data,
  categories,
}: IProps) {
  const state: any = {
    series: [
      {
        name: nameSerie,
        data: data,
      },
    ],
    legend: {
      show: false,
    },

    options: {
      xaxis: {
        categories,
      },
      zoom: false,
      chart: {
        type: "area",
        height: 10,
        width: "100%",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#8fc689"],
      responsive: [
        {
          breakpoint: 1151,
          options: {
            chart: {
              width: "50%", // Adjust the width for smaller screens
            },
          },
        },
      ],
    },
  };
  // useEffect(() => {
  //   const handleResize = () => {
  //     // Adjust chart options based on screen width
  //     const screenWidth = window.innerWidth;

  //     if (screenWidth < 480) {
  //       setState((prevState: { options: { chart: any } }) => ({
  //         ...prevState,
  //         options: {
  //           ...prevState.options,
  //           chart: {
  //             ...prevState.options.chart,
  //             width: "100%",
  //           },
  //         },
  //       }));
  //     } else {
  //       // Adjust options for larger screens
  //       // ...
  //     }
  //   };

  //   // Initial setup
  //   handleResize();

  //   // Attach event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="area"
      height={"30%"}
      width={"100%"}
    />
  );
}
