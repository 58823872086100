import NoData from "components/common/noData";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface Iprops {
  nameSerie: string;
  data: number[] | string[];
  categories: string[];
}

export default function SEOReasons({ nameSerie, data, categories }: Iprops) {
  console.log("categories seo reason ", categories);

  const state: any = {
    series: [
      {
        name: nameSerie.slice(0, 14),
        data: data.slice(0, 14),
        // name: nameSerie,
        // data: data.slice(0, 11),
      },
    ],
    options: {
      colors: ["#0B2349"],
      annotations: {
        points: [
          {
            x: "",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      chart: {
        width: "100%",
        height: 450,
        type: "bar",
        toolbar: {
          show: false,
        },
        xaxis: {
          type: "category",
          labels: {
            fontSize: "15rem",
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0.5,
      },

      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: 0,
          show: false,
          style: {
            colors: [],
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 100,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        categories,
        tickPlacement: "on",
      },
      yaxis: {},
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },
    responsive: [
      {
        breakpoint: 500,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
        xaxis: {
          labels: {
            rotate: -55,
          },
        },
      },
    ],
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [chartWidth, setChartWidth] = useState<any>();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(
    () => (windowSize < 718 ? setChartWidth(380) : setChartWidth(700)),
    [windowSize]
  );
  return (
    <>
      {data.length === 0 && <NoData />}
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        width={chartWidth}
        height={148}
      />
    </>
  );
}
