import React from "react";
import { Select } from "antd";

import PercentageOfPrescriptions from "components/charts/PercentageOfPrescriptions";
import NumberOfPrescription from "components/charts/NumberOfPrescriptions";
import QuantityOfMedicines from "components/charts/QuantityOfMedicines";
import MedicationChart from "components/charts/MedicationChart";
import HeaderLayout from "components/contentLayout";
import FilterBar from "components/filterBar";

import { medicationIcon } from "assets/images/svg";
import {
  medicationBg1,
  medicationBg2,
  medicationBg3,
  medicationBg4,
} from "assets/images/png";
import Loading from "components/common/loading";
import Evolution from "components/common/evolution";
import { margeTimeSelector } from "utils";
import { IStatisticsMedicalManagment } from "interfaces";

/**
 * Medication managment statistics, Plain Text Component: display data only
 * Second page of the dashboard
 * @returns
 */

interface IProps {
  statistics: IStatisticsMedicalManagment | undefined;
  handleChanges: (args: any) => Promise<void>;
  numberDCI: number;
  setNumberDCI: (args: any) => void;
}

function MedicationManagementPlainText({
  statistics,
  handleChanges,
  numberDCI,
  setNumberDCI,
}: IProps) {
  return (
    <div className="bg-[#E2F2F3]">
      <div>
        <HeaderLayout
          title={"Gestion Médicale et Pharmaceutique"}
          subTitle={"Gestion Médicale et Pharmaceutique"}
          logo={medicationIcon}
        />
        <FilterBar handleChanges={handleChanges} />
      </div>
      {statistics ? (
        <div
          className="grid  grid-rows-3 grid-flow-col gap-2 m-2 p-2 bg-[#E2F2F3]"
          style={{
            gridTemplateColumns:
              "repeat(auto-fit, minmax(min(34rem, 100%), 1fr))",
          }}
        >
          <div
            className="col-span-1 block  flex-col rounded-lg w-auto h-[225px] bg-white sm:bg-cover"
            style={{
              backgroundImage: `url(${medicationBg1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "top right",
            }}
          >
            <div className="flex flex-col  gap-1 ml-6">
              <p className="text-[#25265E] font-semibold text-md my-2">
                TAUX DES PRESCRIPTIONS
              </p>
              <p className="text-[#0DA8AE] text-[50px] font-bold ">
                {" "}
                {statistics.medicationsPrescriptionRate.avg + "%"}
              </p>

              <div className="flex justify-between mt-1">
                <NumberOfPrescription
                  nameSerie="Prescriptions"
                  data={statistics.medicationsPrescriptionRate.perDay.data}
                  categories={
                    statistics.medicationsPrescriptionRate.perDay.dates
                  }
                />
              </div>
            </div>
          </div>

          <div
            className="col-span-1 block  flex-col rounded-lg w-auto h-[225px] bg-white sm:bg-cover"
            style={{
              backgroundImage: `url(${medicationBg2})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "top right",
            }}
          >
            <div className="flex flex-col  gap-1 ml-6">
              <p className="text-[#25265E] font-semibold text-md my-2">
                STOCK DE MÉDICAMENTS EN BOITES
              </p>
              <p className="text-[#0DA8AE] text-[50px] font-bold ">
                {" "}
                {statistics.stockMedication.avg + "%"}
              </p>
              <div className="flex mt-1">
                <QuantityOfMedicines
                  nameSerie="Stock de médicaments"
                  data={statistics.stockMedication.perDay.data}
                  categories={statistics.stockMedication.perDay.dates}
                />
              </div>
            </div>
          </div>

          <div
            className="col-span-1 block  flex-col rounded-lg w-auto h-[225px] bg-white sm:bg-cover"
            style={{
              backgroundImage: `url(${medicationBg3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
          >
            <div className="flex flex-col  gap-1 ml-6">
              <p className="text-[#25265E] font-semibold text-md my-2">
                POURCENTAGE DES PRESCRIPTIONS AVEC ANTIBIOTHÉRAPIE
              </p>
              <p className="text-[#0DA8AE] text-[50px] font-bold mt-1">
                {" "}
                {statistics.antibioticCount.avg} %
              </p>
              <div className="flex">
                <PercentageOfPrescriptions
                  nameSerie="Prescriptions avec antibiothérapie"
                  data={statistics.antibioticCount.perDay.data}
                  categories={statistics.antibioticCount.perDay.dates}
                />
              </div>
            </div>
          </div>

          <div
            className="row-span-3 block  flex-col rounded-lg w-auto h-[480px] bg-white sm:bg-cover "
            style={{
              backgroundImage: `url(${medicationBg4})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
          >
            <div className="flex justify-around p-3">
              <p className="text-[#25265E] font-semibold text-sm my-2">
                CLASSEMENT DES MÉDICAMENTS LES PLUS PRESCRITS PAR DCI
              </p>
              <Select
                value={numberDCI}
                style={{ width: 60 }}
                onChange={(value) => {
                  setNumberDCI(Number(value));
                }}
                options={[
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 20, label: 20 },
                ]}
              />
            </div>
            <div className="h-full ">
              <MedicationChart
                data={
                  statistics?.dciOrder
                    ? statistics?.dciOrder?.filter((e, i) => i < numberDCI)
                    : []
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex col justify-center  bg-sky-500/50 p-20 pt-60">
          <Loading type="spin" size="large" />
        </div>
      )}
    </div>
  );
}

export default MedicationManagementPlainText;
