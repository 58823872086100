import React from "react";
import Chart from "react-apexcharts";

type Tdci = {
  grouped_dci: string;
  total_count: number;
};

type Tprops = {
  data: Tdci[] | [];
};

export default function MedicationChart({ data }: Tprops) {
  const state: any = {
    series: data?.map((item) => item.total_count),
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },

      labels: data?.map((item) => item.grouped_dci),
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (
          val: string,
          opts: {
            w: { globals: { series: { [x: string]: string } } };
            seriesIndex: string | number;
          }
        ) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "start",
        floating: false,
        fontSize: "12px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 450,
        inverseOrder: false,
        width: 0,
        height: 0,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 500,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="donut"
        height={380}
        width={360}
      />
    </>
  );
}
