import { apiSlice } from "app/api/apiSlice";

/*api regions get all regions */
export const regionsApiSilce = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query<any, void>({
      query: () => "/region",
    }),
  }),
});
export const { useGetRegionsQuery } = regionsApiSilce;
