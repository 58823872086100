const APP_CONSTANTS = {
  LOCALSTORAGE_PREFIX: "digihealth_",
};

const ROUTE_CONSTANTS = {
  LOGIN: {
    PATH: "/login",
    title: "login",
  },
  PATIENTS: {
    PATH: "/patients",
    title: "Administration des Patients et Services",
  },
  MEDICATION_AND_PHARMA_MANAGEMENT: {
    PATH: "/medication",
    title: "Gestion Médicale et Pharmaceutique",
  },
  CLINICAL_PERFORMANCE: {
    PATH: "/performance",
    title: "Performance Clinique et Épidémiologie",
  },
  TELEMEDCINE: { PATH: "/telemedcine", title: "Télémédecine" },
  BILLING: { PATH: "/billing", title: "Facturation et Cotation" },
};

export { APP_CONSTANTS, ROUTE_CONSTANTS };
