import React from "react";

import HeaderLayout from "components/contentLayout";
import NumberOfClinicalProcedures from "components/charts/NumberOfClinicalProcedures";
import TypologyOfPathologies from "components/charts/TypologyOfPathologies";
import RateOfManagedCases from "components/charts/RateOfManagedCases";
import IncidenceAndPrevalenceOfPathologies from "components/charts/IncidenceAndPrevalenceOfPathologies";
import FilterBar from "components/filterBar";

import {
  medicationIcon,
  HTA_icon,
  Cancer_sein_icon,
  luterus_icon,
  teleIcon1,
  teleIcon2,
  headIcon,
} from "assets/images/svg";
import { performanceBg1, performanceBg3 } from "assets/images/png";
import Loading from "components/common/loading";

import { margeTimeSelector } from "utils";
import Evolution from "components/common/evolution";
import { IStatisticsClinicalPerformance } from "interfaces";

/**
 * Clinical performance statistics, Plain text component: display data only
 * Third page of the dashboard
 * @returns
 */

interface IProps {
  statistics: IStatisticsClinicalPerformance | undefined; //IStatisticsClinicalPerformance | undefined;
  handleChanges: (args: any) => Promise<void>;
}

export default function ClinicalPerformancePlainText({
  statistics,
  handleChanges,
}: IProps) {
  return (
    <div className="bg-[#E2F2F3] gap-3">
      <div>
        <HeaderLayout
          title={"Performance Clinique et Épidémiologie"}
          subTitle={"Performance Clinique et Épidémiologie"}
          logo={medicationIcon}
        />
        <FilterBar handleChanges={handleChanges} />
      </div>
      {statistics ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(24rem,1fr))",
          }}
        >
          <div className="col-span-2">
            <div className="flex flex-col">
              <div
                className="grid grid-cols-2 gap-3 mx-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit,minmax(24rem,1fr))",
                }}
              >
                <div
                  className="block  flex-col gap-2 px-2 rounded-lg w-auto h-auto bg-white"
                  style={{
                    backgroundImage: `url(${performanceBg1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "top right",
                  }}
                >
                  <p className="text-[#25265E] font-semibold text-sm my-2">
                    NOMBRE D'ACTES CLINIQUES
                  </p>
                  <p className="text-[#0DA8AE] text-[40px] font-bold">
                    {statistics.countMedicalAct.total}
                  </p>
                  <div className="flex justify-between">
                    <NumberOfClinicalProcedures
                      nameSerie="Actes cliniques"
                      data={statistics.countMedicalAct.perDay.data}
                      categories={statistics.countMedicalAct.perDay.dates}
                    />
                  </div>
                </div>
              </div>

              <div className="mx-3 gap-4 pb-5">
                <div
                  className="h-auto flex flex-col divide-y gap-4  p-2 my-2  rounded-lg  bg-white"
                  style={{
                    backgroundImage: `url(${performanceBg3})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "top right",
                  }}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 divide-x-2 gap-3 ">
                    <div className="relative  mb-12 px-3 lg:mb-0">
                      <div className="mb-2 flex">
                        <p className="text-[#25265E] font-bold text-md my-2 ">
                          Diabéte
                        </p>
                        <img src={HTA_icon} alt="" className="h-10 w-10" />
                      </div>
                      <p className="text-[#0DA8AE] text-md font-bold">
                        {statistics.diabeteScreening.total}
                      </p>
                      {/* <h5 className=" text-[#25265E] ">Taux de suspection</h5>
                      <p className="text-[#EA8C00] text-md font-bold">25%</p> */}
                      <IncidenceAndPrevalenceOfPathologies
                        nameSerie="Dépistages"
                        data={statistics.diabeteScreening.perDay.data}
                        categories={statistics.diabeteScreening.perDay.dates}
                      />{" "}
                    </div>

                    <div className="relative mb-12 px-3 lg:mb-0">
                      <div className="mb-2 flex">
                        <p className="text-[#25265E] font-bold text-md my-2 ">
                          HTA
                        </p>

                        <img
                          src={HTA_icon}
                          alt=""
                          className="absolute h-10 w-10 m-5 inset-y-0 right-0"
                        />
                      </div>
                      <p className="text-[#0DA8AE] text-md font-bold">
                        {statistics.htaScreening.total}
                      </p>
                      {/* <h5 className=" text-[#25265E] text-sm">
                        Taux de suspection
                      </h5>
                      <p className="text-[#EA8C00] text-md font-bold">56%</p> */}
                      <IncidenceAndPrevalenceOfPathologies
                        nameSerie="Dépistages"
                        data={statistics.htaScreening.perDay.data}
                        categories={statistics.htaScreening.perDay.dates}
                      />

                      <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
                    </div>
                    <div className="relative mb-12 px-3 lg:mb-0">
                      <div className="mb-2 flex">
                        <p className="text-[#25265E] font-bold  text-md  my-2 ">
                          Cancer du sein
                        </p>
                        <img
                          src={Cancer_sein_icon}
                          alt=""
                          className="absolute h-10 w-10 m-5 inset-y-0 right-0"
                        />
                      </div>
                      <p className="text-[#0DA8AE] text-md font-bold">
                        {statistics.breastCancerScreening.total}
                      </p>
                      {/* <h5 className=" text-[#25265E] text-sm">
                        Taux de suspection
                      </h5>
                      <p className="text-[#EA8C00] text-md font-bold">1%</p> */}
                      <IncidenceAndPrevalenceOfPathologies
                        nameSerie="Dépistages"
                        data={statistics.breastCancerScreening.perDay.data}
                        categories={
                          statistics.breastCancerScreening.perDay.dates
                        }
                      />

                      <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
                    </div>
                    <div className="relative mb-12 px-3 lg:mb-0">
                      <div className="mb-2 flex">
                        <p className="text-[#25265E] font-bold text-md my-2 z-10">
                          Cancer du col de l'utérus
                        </p>
                        <img
                          src={luterus_icon}
                          alt=""
                          className="absolute h-10 w-10 m-5 inset-y-0 right-0"
                        />
                      </div>
                      <p className="text-[#0DA8AE] text-md font-bold">
                        {statistics.cervicalCancerScreening.total}
                      </p>
                      {/* <h5 className=" text-[#25265E] text-sm">
                        Taux de suspection
                      </h5>
                      <p className="text-[#EA8C00] text-md font-bold">0.7%</p> */}
                      <IncidenceAndPrevalenceOfPathologies
                        nameSerie="Dépistages"
                        data={statistics.cervicalCancerScreening.perDay.data}
                        categories={
                          statistics.cervicalCancerScreening.perDay.dates
                        }
                      />
                      <div className="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-3">
            <div className="block flex-col gap-2 p-3 rounded-lg w-auto h-[96%] bg-white">
              <p className="text-[#25265E] font-semibold text-sm">
                Typologie des pathologies diagnostiquées selon la CIM-11.
              </p>
              <TypologyOfPathologies data={statistics.groupByPathology} />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex col justify-center  bg-sky-500/50 p-20 pt-60">
          <Loading type="spin" size="large" />
        </div>
      )}
    </div>
  );
}
