import React, { useEffect, useState } from "react";
import { useGetStatisticsHooks } from "hooks/useGetStatistics";
import BillingPlainText from "./BillingPlainText";
import { restructStatisticsBillings } from "utils/restructStatistics";
import { getStartAndEndDayOfWeek } from "utils";

/**
 * Billing statistics, container component: should fetch data, waiting fot API
 * Fifth page of the dashboard
 * @returns
 */

export default function BillingContainer() {
  /*state*/
  const [statistics, setStatistic] = useState<any>(undefined);
  /*hook*/
  const getStatistics = useGetStatisticsHooks();

  /* handle change of filter by getting new data from server */
  const handleChanges = async (filter: any) => {
    const resStatistics: any = await getStatistics(filter, 5);
    /*No data available*/
    if (resStatistics.data) {
      const restructedData = restructStatisticsBillings(resStatistics.data);
      setStatistic(restructedData);
    }
  };

  return (
    <BillingPlainText statistics={statistics} handleChanges={handleChanges} />
  );
}
