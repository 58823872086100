import React, { useState } from "react";
import { useGetStatisticsHooks } from "hooks/useGetStatistics";
import {
  IStatisticsMedicalManagment,
  IStatisticsMedicalManagmentDataServer,
} from "interfaces";
import MedicationManagementPlainText from "./MedicationManagementPlainText";
import { restructStatisticsMedicationManagment } from "utils/restructStatistics";

/**
 * Medication managment statistics, smart component: fetch, restruct and deal with data
 * Second page of the dashboard
 * @returns
 */

function MedicationManagementContainer() {
  /*state*/
  const [numberDCI, setNumberDCI] = useState<number>(5);
  const [statistics, setStatistic] = useState<IStatisticsMedicalManagment>();
  /*hooks*/
  const getStatistics = useGetStatisticsHooks();

  const handleChanges = async (filter: any) => {
    /* getStatistics(filter, 2) 2 indicates that we want statistics for medication managment*/
    const resStatistics: any = await getStatistics(filter, 2);

    if (resStatistics.data) {
      const restructureData: IStatisticsMedicalManagment =
        restructStatisticsMedicationManagment(resStatistics.data);
      setStatistic(restructureData);
    }
  };

  return (
    <MedicationManagementPlainText
      statistics={statistics}
      handleChanges={handleChanges}
      numberDCI={numberDCI}
      setNumberDCI={setNumberDCI}
    />
  );
}

export default MedicationManagementContainer;
