import React, { useEffect, useState } from "react";

import Loading from "components/common/loading";
import { useGetStatisticsHooks } from "hooks/useGetStatistics";

import { IStatisticsPatients, IStatisticsPatientsDataServer } from "interfaces";
import PatientsPlainTextComponent from "pages/patientsAdministration/PatientsPlainText";
import { restructStatisticsPatientsManagment } from "utils/restructStatistics";
const data = {
  totalTakenCare: {
    cumuleFromStart: 108436,
    perday: [
      586, 623, 655, 726, 661, 691, 513, 862, 754, 796, 985, 773, 881, 859, 761,
      856, 706, 806, 887, 910, 829, 815, 1075, 1041, 1036, 1226, 1096, 1333,
      1338, 1291, 1276, 1421, 1537, 1609, 1659, 1508, 1559, 1545, 1487, 1409,
      1379, 1323, 1426, 1345, 1317, 1106, 1356, 1273, 1223, 1269, 1656, 1468,
      1376, 1366, 1032, 1677, 1638, 1451, 1440, 1517, 1617, 1396, 1352, 1368,
      1332, 1549, 1452, 1840, 1405, 595, 1427, 1291, 1318, 1317, 1292, 1559,
      1408, 1531, 1210, 1221, 1374, 1127, 1173, 1330, 1274, 1303, 1396, 1306,
      399, 1255,
    ],
  },
  totalConsultation: [],
  totalAdmissions: 0,
  totalNewPatient: [100],
  genderCount: {
    femaleCount: 1,
    maleCount: 1,
  },
  avgTimeConsultation: {
    avg: [
      {
        avg_consultations: "00m:00s",
      },
    ],
    selectedWeek: [],
  },
  agesSeries: ["age1", 2],
  transfertCount: [],
  countMotifsTransfert: [],
};
/**
 * Administration of patients and services statistics, container/smart component: fetch and deal data
 * First page of the dashboard
 * @returns
 */

export default function PatientsContainer() {
  /* State of statistics*/
  const [statistics, setStatistics] = useState<IStatisticsPatients>();

  /* Hook */
  const getStatistics = useGetStatisticsHooks();

  /* Handle change of filter by getting new data from server */
  const handleChanges = async (filter: any) => {
    /* GetStatistics(filter, 1) 1 indicates that we want statistics for patients administrations and services*/
    const resStatistics: any = await getStatistics(filter, 1);
    if (resStatistics.data) {
      const restructedStatistics: IStatisticsPatients =
        restructStatisticsPatientsManagment(resStatistics.data);
      setStatistics(restructedStatistics);
    }
  };

  return (
    <PatientsPlainTextComponent
      statistics={statistics}
      handleChanges={handleChanges}
    />
  );
}
