import { fondationMed5, logoMinistreSante } from "assets/images/jpg";
import { logoDigihealth, logoMediot } from "assets/images/svg";
import React from "react";

interface IProps {
  componentId?: string;
}

const PrintComponentButton = ({ componentId }: IProps) => {
  const handlePrint = () => {
    const content = document.getElementById("totalTakenCare")?.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow?.document.write(
      `<html><head><title style="font-size:30px; font-color:blue">
      Digihealth
      </title>
      <div style="width:100%; display: flex;
      flex-direction:row;
      justify-content:space-between;
      align-items:center;">
        <img src=${logoMediot} style='width:150px; height:50px' /> 
        <div>
            <img src=${fondationMed5} style='width:150px; height:50px' /> 
            <img src=${logoMinistreSante} style='width:150px;height:50px'  />
        </div>
      </div>
      <style>
    body {
      display: flex;
      flex-direction:column;
      justify-content: flex-start;
      align-items: center;
      height: 100vh; /* Make the body fill the viewport height */
      margin: 0; /* Remove default margin */
    }
  </style>

      </head>
      <body style="">`
    );
    if (newWindow && content) {
      newWindow?.document.write(
        "<h1 style='margin-bottom:100px' >Digihealth</h1>"
      );

      newWindow?.document.write(content);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close();
      newWindow?.print();
    }
  };
  return <button onClick={() => handlePrint()}>Imprimer</button>;
};

export default PrintComponentButton;
