/*chart enum */

export enum chart {
  TOTAL_PATIENTS = "totalPatients",
  NEW_PATIENTS = "newPatients",
  TOTAL_BENEFITS = "TotalBenefits",
  AVERAGE_DURATION = "AverageDuration",
  TELE_EXPERTISES = "TeleExpertises",
  SEO_REASONS = "SEOReasons",
}
