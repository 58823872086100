import React, { Component, useEffect, useState } from "react";
import { DatePicker, Select, Spin, Space, Dropdown } from "antd";

import type { DatePickerProps } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import moment from "moment";

import { useGetRegionsQuery } from "features/regions/regionsApi";
import { useGetProvincesQuery } from "features/provinces/provinceApi";
import { useGetUnitsQuery } from "features/unit/unitApi";
import { getStartAndEndDayOfWeek } from "utils";
import { RangePickerProps } from "antd/es/date-picker";
import ExcelExport from "components/exportButton/ExcelExport";
import ExportPDFButton from "components/exportButton/ExportPdfButton";
import PrintButton from "components/exportButton/PrintScreenPdf";
import { AiFillFileExcel, AiFillFilePdf, AiFillPrinter } from "react-icons/ai";
import DropDownMenuExport from "components/exportButton/DropDownMenu";
const { RangePicker } = DatePicker;

const defaultRegionValue = { value: -1, label: "Région: tous" };
const defaultProvinceValue = { value: -1, label: "Province: tous" };
const defaulUnitValue = { value: -1, label: "Unité: tous" };

type TFilter =
  | {
      regionId?: number;
      provinceId?: number;
      unitId?: number;
      date?: Date;
    }
  | any;

interface IFilterProps {
  statisticsData?: any | undefined;
  Component?: any;
  fileName?: string;
  handleChanges: (args: any) => any;
  componentId?: string;
}

function FilterBar({ statisticsData, fileName, handleChanges }: IFilterProps) {
  //states
  const [filter, setFilter] = useState<TFilter>({});
  const [dateValue, setDateValue] = useState<{ from: Date; to: Date }>(
    // getStartAndEndDayOfWeek(new Date())
    { from: new Date("11/01/2023"), to: new Date() }
  );

  //apiCall
  const { data: regionsData, isLoading: isLoadingRegions } =
    useGetRegionsQuery();

  const { data: provincesData, isLoading: isLoadingProvinces } =
    useGetProvincesQuery({ regionId: filter.regionId });

  const { data: unitsData, isLoading: isLoadingUnits } = useGetUnitsQuery({
    regionId: filter?.regionId,
    provinceId: filter?.provinceId,
  });

  //Hooks
  useEffect(() => {
    // onChangeRange([dayjs("01/11/2023"), dayjs()]);
    // setDateValue({ from: new Date("11/01/2023"), to: new Date() });
  }, []);

  useEffect(() => {
    regionsData && handleChanges({ regionId: -1, dateValue });
  }, [regionsData]);

  useEffect(() => {
    regionsData && handleChanges({ ...filter, dateValue });
  }, [filter, dateValue]);

  //Handlers
  const onChangeDateWeek: DatePickerProps["onChange"] = (
    date: any,
    dateString
  ) => {
    const { from, to } = getStartAndEndDayOfWeek(new Date(date));
    console.log("from to week", from, to);
    setDateValue({ from, to });
  };
  const onChangeRange = (dateRange: any) => {
    let [from, to] = dateRange;
    if (from > to) return;
    console.log("dateRange", from, to);
    //from
    from = new Date(from);
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    //to
    to = new Date(to);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(0);
    // to = new Date(to).setTime((23 * 60 + 59) * 60 * 1000);
    console.log("dateRange");
    console.log(from);
    console.log(to);
    setDateValue({ from, to });
  };

  const initSelectOption = (data: any, defaultValue: any) => {
    const res = data.map((item: any) => {
      return { value: item.id, label: item.name };
    });
    res.unshift(defaultValue);
    return res;
  };

  const handleFilterChange = (newFilter: any) => {
    //update filter
    if (newFilter.regionId === -1) setFilter({});
    else if (newFilter.regionId) setFilter(newFilter);
    else if (newFilter.provinceId === -1)
      setFilter({ regionId: filter.regionId });
    else setFilter({ ...filter, ...newFilter });
  };

  //
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before and after current week
    return (
      current &&
      !(
        current > dayjs("2023-10-31").endOf("day") &&
        current < dayjs().endOf("day")
      )
    );
  };

  const disabledDateWeek: DatePickerProps["disabledDate"] = (current: any) => {
    const startDate = moment("2023-11-01"); // Define your start date
    const endDate = moment(); //Define your end date

    // Disable weeks between startDate and endDate
    return (
      current &&
      current.week() >= startDate.week() &&
      current.week() <= endDate.week()
    );
  };

  return (
    <>
      <div>
        <div className="grid lg:grid-cols-7 md:grid-3 sm:grid-cols-1 my-3 mx-2 p-2 gap-4">
          <Select
            size={"middle"}
            defaultValue={defaultRegionValue}
            onChange={(value) => handleFilterChange({ regionId: value })}
            options={
              regionsData?.results &&
              initSelectOption(regionsData.results, defaultRegionValue)
            }
            notFoundContent={isLoadingRegions ? <Spin size="small" /> : null}
          />
          <Select
            key={provincesData?.results[0]?.name}
            size={"middle"}
            onChange={(value) => handleFilterChange({ provinceId: value })}
            defaultValue={defaultProvinceValue}
            options={
              provincesData?.results &&
              initSelectOption(provincesData.results, defaultProvinceValue)
            }
            notFoundContent={isLoadingProvinces ? <Spin size="small" /> : null}
          />

          <Select
            key={unitsData?.results[0]?.name}
            size={"middle"}
            onChange={(value) => handleFilterChange({ unitId: value })}
            defaultValue={defaulUnitValue}
            options={
              unitsData?.results &&
              initSelectOption(unitsData.results, defaulUnitValue)
            }
            notFoundContent={isLoadingUnits ? <Spin size="small" /> : null}
          />
          <DatePicker
            value={dayjs(dateValue.from)}
            onChange={onChangeDateWeek}
            disabledDate={disabledDateWeek}
            picker="week"
            locale={locale}
          />
          <div className="col-span-2">
            <RangePicker
              value={[dayjs(dateValue.from), dayjs(dateValue.to)]}
              locale={locale}
              onChange={onChangeRange}
              disabledDate={disabledDate}
            />
          </div>

          <DropDownMenuExport
            Component={Component}
            statisticsData={statisticsData}
            fileName={fileName}
            rangeDate={dateValue}
          />
        </div>
      </div>
    </>
  );
}

export default FilterBar;
