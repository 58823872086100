// authApi.ts
import { apiSlice } from "app/api/apiSlice";

const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ login, password }) => ({
        url: "/auth/login",
        method: "POST",
        body: { login, password },
      }),
    }),
    logout: builder.mutation({
      query: ({ refreshToken }) => ({
        url: "/auth/SignOut",
        method: "POST",
        body: { refreshToken },
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;

export default authApi;
