import { IFilter } from "interfaces";

/* generate a query to send to server from the Filter elements*/
export function getQueryFromFilter(filter: IFilter) {
  let q = "";
  if (filter.unitId > 0) {
    q = `centerId=${filter.unitId}`;
  } else if (filter.provinceId > 0) {
    q = `provinceId=${filter.provinceId}`;
  } else if (filter.regionId > 0) {
    q = `regionId=${filter.regionId}`;
  }

  return q;
}
