import React from "react";
import Chart from "react-apexcharts";

interface Iprops {
  nameSerie: string;
  data: number[] | string[];
  categories: string[];
}

export default function QuantityOfMedicines({
  nameSerie,
  data,
  categories,
}: Iprops) {
  const state: any = {
    series: [
      {
        name: nameSerie,
        data: data,
      },
    ],
    legend: {
      show: false,
    },

    options: {
      zoom: false,
      chart: {
        type: "area",
        height: 10,
        width: "100%",
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#8fc689"],
      responsive: [
        {
          breakpoint: 1151,
          options: {
            chart: {
              width: "100%", // Adjust the width for smaller screens
            },
          },
        },
      ],
      xaxis: {
        categories,
      },
    },
  };

  return (
    <div
      className="flex justify-start flex-wrap items-start  -mt-5"
      style={{ width: "100%", height: "100px" }}
    >
      <Chart
        options={state.options}
        series={state.series}
        type="area"
        height={"100%"}
        width={"188%"}
      />
    </div>
  );
}
