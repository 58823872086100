import ReactLoading from "react-loading";
import { Spin } from "antd";

interface IProps {
  type: "spin" | "default";
  size?: "small" | "large" | "default";
  color?: string;
}

const Loading = ({ type, size = "default", color = "#fff" }: IProps) => {
  return type === "spin" ? (
    <Spin size={size} />
  ) : (
    <ReactLoading type="spin" color={color} height={20} width={20} />
  );
};

export default Loading;
