import React from "react";

import HeaderLayout from "components/contentLayout";
import FilterBar from "components/filterBar";
import Evolution from "components/charts/Evolution";

import { facturationIcon } from "assets/images/svg";
import { facturationBg1, facturationBg2 } from "assets/images/png";
import BreakDownByService from "components/charts/BreakDownByPrestation";
import NoData from "components/common/noData";
import Loading from "components/common/loading";

/**
 * Billing statistics, container component: display data only
 * Fifth page of the dashboard
 * @returns
 */

interface IProps {
  statistics: any;
  handleChanges: (args: any) => void;
}

export default function BillingPlainText({
  statistics,
  handleChanges,
}: IProps) {
  return (
    <>
      {/* <div className="grid place-items-center fixed w-screen h-screen bg-black bg-opacity-60 backdrop-blur-sm z-20">
        <div className="text-xl font-bold lg:-ml-80 -mt-40 text-white px-10 py-5 bg-[#0bafdf] rounded-2xl">
          En cours de construction
        </div>
      </div> */}
      <div className="bg-[#E2F2F3] gap-3">
        <div>
          <HeaderLayout
            title={"Facturation et Cotation"}
            subTitle={"Facturation et Cotation"}
            logo={facturationIcon}
          />
          <FilterBar handleChanges={handleChanges} />
        </div>
        {statistics ? (
          <>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 mx-3 gap-5 bg-[#E2F2F3]">
              <div
                className="block  flex-col gap-4 p-2 rounded-lg w-auto h-auto bg-white"
                style={{
                  backgroundImage: `url(${facturationBg1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "top right",
                }}
              >
                <p className="text-[#25265E] font-semibold text-sm my-2">
                  VALEUR MOYENNE DES FACTURES INTERNES SELON LA NGAP
                </p>

                {statistics?.actDataBilling?.map((item: any) => (
                  <>
                    <div className="grid grid-cols-2 p-1 w-full">
                      <p className="text-[#0DA8AE] font-semibold text-md">
                        {item?.category}
                      </p>
                      <p className="text-[#25265E] font-semibold text-md flex justify-end self-end">
                        {item?.total_price}
                      </p>
                    </div>
                    <hr className="border-gray" />
                  </>
                ))}
              </div>

              {/* seconde card */}
              <div
                className="block  flex-col gap-4 p-2 rounded-lg w-auto h-auto bg-white"
                style={{
                  backgroundImage: `url(${facturationBg2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "top right",
                }}
              >
                <p className="text-[#25265E] font-semibold text-sm my-2">
                  CA ventille par prestation CA VENTILLE PAR PRESTATION
                </p>
                <div className="grid grid-cols-1">
                  <BreakDownByService
                    nameSerie={"CA ventille par prestation"}
                    data={statistics?.actData || []}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 m-3">
              <div className="block h-auto flex-col gap-4 p-2 rounded-lg  bg-white">
                <p className="text-[#25265E] font-semibold text-sm my-2">
                  EVOLUTION
                </p>
                <Evolution data={statistics.dataEvolution} />
              </div>
            </div>
          </>
        ) : (
          <div className="w-full h-screen flex col justify-center  bg-sky-500/50 p-20 pt-60">
            <Loading type="spin" size="large" />
          </div>
        )}
      </div>
    </>
  );
}
