import React from "react";
import Chart from "react-apexcharts";

interface Iprops {
  nameSerie: string | "default name serie";
  data: number[] | string[] | [];
  categories: string[] | [];
}

export default function TotalBenefits({ nameSerie, data, categories }: Iprops) {
  const state: any = {
    series: [
      {
        name: nameSerie,
        data: data,
      },
    ],
    legend: {
      show: false,
    },

    options: {
      zoom: false,
      chart: {
        type: "area",
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#8fc689"],

      xaxis: {
        categories,
      },
    },
    responsive: [
      {
        breakpoint: 1151,
        options: {
          width: "100%",
        },
      },
    ],
  };

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="area"
        height={"56%"}
      />
    </>
  );
}
