import React, { useState } from "react";
import Layout from "components/layout";
import { Routes, Route } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/index";

import LoginContainer from "pages/login/LoginContainer";
import RequiredAuth from "features/requireAuth/requiredAuth";

import PatientsContainer from "pages/patientsAdministration/PatientsContainer";
import MedicationManagementContainer from "pages/medicalManagement/MedicationManagementContainer";
import TelemedicineContainer from "pages/telemedecine/TelemedecineContainer";
import BillingContainer from "pages/billing/BillingContainer";
import ClinicalPerformanceContainer from "pages/clinicalPerformance/ClinicalPerformanceContainer";

function App() {
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route index path="/login" element={<LoginContainer />} />
        <Route element={<RequiredAuth />}>
          <Route path="/" element={<Layout />}>
            {/* Protected Routes */}
            <Route
              index
              path={ROUTE_CONSTANTS.PATIENTS.PATH}
              element={<PatientsContainer />}
            />
            <Route
              path={ROUTE_CONSTANTS.MEDICATION_AND_PHARMA_MANAGEMENT.PATH}
              element={<MedicationManagementContainer />}
            />
            <Route
              path={ROUTE_CONSTANTS.CLINICAL_PERFORMANCE.PATH}
              element={<ClinicalPerformanceContainer />}
            />
            <Route
              path={ROUTE_CONSTANTS.TELEMEDCINE.PATH}
              element={<TelemedicineContainer />}
            />
            <Route
              path={ROUTE_CONSTANTS.BILLING.PATH}
              element={<BillingContainer />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
