import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface Iprops {
  nameSerie: string | "default name serie";
  data: number[] | string[] | [];
  categories: string[] | [];
}

export default function NumberOfNewPatients({
  nameSerie,
  data,
  categories,
}: Iprops) {
  const [state, setSate] = useState<any>({
    series: [
      {
        name: nameSerie,
        data,
      },
    ],
    options: {
      colors: ["#08A5AB"],

      annotations: {
        points: [
          {
            x: "",
            seriesIndex: 0,
            label: {
              borderColor: "#16a4e1",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#5d8bd0",
              },
            },
          },
        ],
      },
      chart: {
        width: "100%",
        height: 450,
        type: "bar",
        toolbar: {
          show: false,
        },
        sparkline: {
          // enabled: data?.length < 20 ? false : true,
          enabled: true,
        },
        categories: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: "8%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
      },

      grid: {
        show: true,
      },
      xaxis: {
        labels: {
          rotate: -25,
        },
        categories,
        tickPlacement: "on",
      },
      yaxis: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },

    responsive: [
      {
        breakpoint: "22rem",
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  useEffect(() => {
    if (data) {
      setSate({
        ...state,
        series: [
          {
            name: nameSerie,
            data: data,
          },
        ],
        options: {
          ...state.options,
          chart: {
            ...state.chart,
            sparkline: {
              enabled: data?.length < 20 ? false : true,
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type={"bar"}
        height={100}
      />
    </>
  );
}
