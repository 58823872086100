import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { apiSlice } from "app/api/apiSlice";

import authReducer from "features/auth/authSlice";
import authApi from "features/auth/authApi";
import regionsReducer from "features/regions/regionsSlice";
import provincesReducer from "features/provinces/provinceSlice";
import unitsReducer from "features/unit/unitSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,

    auth: authReducer,
    regions: regionsReducer,
    provinces: provincesReducer,
    units: unitsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(apiSlice.middleware),
  devTools: true,
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
