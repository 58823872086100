import { apiSlice } from "app/api/apiSlice";

/*get all provinces or get provinces by regionId  */
const provincesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProvinces: builder.query<any, { regionId?: number }>({
      query: ({ regionId }) => {
        console.log("regionId", regionId);
        if (regionId) {
          const query = JSON.stringify({ regionId });
          return `/province?where=${query}`;
        } else {
          return "/province";
        }
      },
    }),
  }),
});
export const { useGetProvincesQuery } = provincesApi;

export default provincesApi;
