/* DEFINING POSSIBLE VALUES FOR LOADING STATE */
export enum LOADING_STATE {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

/* DEFINING STORE SLICES */
export enum SLICE {
  APP = "app",

  /* basic slices */
  AUTH = "authSlice",
  REGION = "regionsSlice", // this slice is to detect if the internet connection is up or down
  PROVINCE = "provincesSlice", // this is form slice
  UNIT = "unitsSlice",
  STATISTICS = "statistics",
  STATIC_DATA = "staticSlice",
}
