import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "features/auth/authApi";
import { setCredentials } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import { ROUTE_CONSTANTS } from "constants/index";
import LoginPlainText from "./LoginPlainText";

/**
 * Login, container/smart component: send credentials and deal with the logic
 * First page of the dashboard
 * @returns
 */

export default function LoginContainer() {
  const userNameRef = useRef<HTMLInputElement>(null);
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userNameRef.current?.focus();
  }, []);

  const handleLogin = async () => {
    try {
      const userData = await login({
        login: userName,
        password,
      }).unwrap();
      if (userData.data?.user) {
        const credentialsInfo = {
          user: userData.data.user,
          accessToken: userData.tokens.accessToken,
          refreshToken: userData.tokens.refreshToken,
        };
        dispatch(setCredentials(credentialsInfo));
        setUserName("");
        setPassword("");
        navigate(ROUTE_CONSTANTS.PATIENTS.PATH);
      }
    } catch (e: any /*tochange*/) {
      if (e.status === 401)
        toast.error("Le nom d'utilisateur ou le mot de passe est incorrect");
      else if (e.status.toString().chartAt(0) === "5")
        toast.error(
          "Problème de communication avec le serveur, Veuillez réessayer plus tard"
        );
    }
  };

  return (
    <LoginPlainText
      setUserName={setUserName}
      setPassword={setPassword}
      handleLogin={handleLogin}
      isLoading={isLoading}
    />
  );
}
