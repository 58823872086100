import { useLazyGetstatisticsQuery } from "features/statistics/statisticsApi";
import { getQueryFromFilter, getStartAndEndDayOfWeek } from "utils";
import { IFilter } from "interfaces";

/**
 * Global hook to get statistics for all dashboard
 * @returns
 */
export const useGetStatisticsHooks = () => {
  /*redux-toolkit lazy query  */
  const [trigger, { isLoading, isError, isSuccess }, lastPromiseInfo] =
    useLazyGetstatisticsQuery();

  return async function (filter: IFilter, page: number) {
    // generate a query from Filter
    const q = getQueryFromFilter(filter);
    // Get start and end days of week
    // const { from, to } = getStartAndEndDayOfWeek(new Date(filter.dateValue));
    const { from, to } = filter.dateValue;

    return await trigger({
      id: page,
      q,
      from,
      to,
    });
  };
};
