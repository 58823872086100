import React, { useState } from "react";

import HeaderLayout from "components/contentLayout";
import FilterBar from "components/filterBar";
import MyCard from "components/common/DynamicCard";
import DistributionByAgeChart from "components/charts/DistributionByAge";
import DistrubutionBySex from "components/charts/DistributionBySex";

import {
  patientIcon,
  headIcon,
  clockIcon,
  clockIcon2,
  teleexpertiseIcon,
  motifIcon,
  administrationIcon2,
} from "assets/images/svg";
import Loading from "components/common/loading";

import { margeTimeSelector } from "utils";
import { chart } from "enums/index";
import { IStatisticsPatients } from "interfaces";
import PatientsContainer from "./PatientsContainer";

/**
 * Administration of patients and services statistics, plain text component: display data only
 * @returns
 */

interface IProps {
  statistics: IStatisticsPatients | undefined;
  handleChanges: (args: any) => Promise<void>;
}

function PatientsPlainTextComponent({ statistics, handleChanges }: IProps) {
  const [componentId, setComponentId] = useState<string>();
  return (
    <div className="bg-[#E2F2F3]">
      <div>
        <HeaderLayout
          title={"Administration des Patients et Services"}
          subTitle={"Administration des Patients et Services"}
          logo={administrationIcon2}
        />
        <FilterBar
          handleChanges={handleChanges}
          statisticsData={statistics}
          Component={PatientsContainer}
          fileName="Administration des Patients et Services"
        />
      </div>
      {statistics ? (
        <div
          className=" bg-[#E2F2F3] m-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(min(25rem,100%),1fr))",
          }}
        >
          <MyCard
            title={"TOTAL DES PRISES EN CHARGE DEPUIS LE 01/11/2023"}
            logo={patientIcon}
            typeChart={chart.TOTAL_PATIENTS}
            count={statistics?.totalTakenCare.cumuleFromStart}
            percent={6}
            progress={true}
            nameSerie="Total de Patients"
            data={statistics.totalTakenCare.perday.data}
            // categories={margeTimeSelector().week}
            categories={statistics.totalTakenCare.perday.dates}
          />

          <div>
            <MyCard
              title={"TAUX DE NOUVEAUX PATIENTS"}
              logo={patientIcon}
              typeChart={chart.NEW_PATIENTS}
              percent={15}
              progress={true}
              count={statistics.newPatient.avg_rate}
              unité={"%"}
              nameSerie="Taux de nouveaux Patients"
              data={statistics.newPatient.perDay.data}
              categories={statistics.newPatient.perDay.dates}
            />
          </div>
          <div>
            <MyCard
              title={"TOTAL DES PRESTATIONS"}
              subTitle="NOMBRE D'ADMISSIONS"
              logo={headIcon}
              typeChart={chart.TOTAL_BENEFITS}
              percent={8}
              progress={true}
              count={statistics.totalPrestation.total}
              subCount={statistics.totalAdmissions}
              nameSerie="Prestation"
              data={statistics.totalPrestation.perDay.data}
              categories={statistics.totalPrestation.perDay.dates}
            />
          </div>
          <div>
            <MyCard
              title={"DURÉE MOYENNE DES CONSULTATIONS"}
              unité={""}
              secondeIcon={clockIcon2}
              logo={clockIcon}
              typeChart={chart.AVERAGE_DURATION}
              percent={8}
              progress={false}
              count={statistics.avgTimeConsultation.avg[0].avg_consultations}
              nameSerie="Duée Moyenne en minute"
              data={statistics.avgTimeConsultation.perDay.data}
              categories={[]} //statistics.avgTimeConsultation.perDay.dates
            />
          </div>
          <div>
            <DistributionByAgeChart
              title="RÉPARTITION PAR AGE"
              data={statistics.agesSeries}
            />
          </div>
          <div>
            <DistrubutionBySex
              femaleCount={statistics.genderCount.femaleCount}
              maleCount={statistics.genderCount.maleCount}
            />
          </div>
          <div>
            <MyCard
              title={"TAUX DE TRANSFERT DES PATIENTS"}
              unité={"%"}
              logo={teleexpertiseIcon}
              typeChart={chart.TELE_EXPERTISES}
              percent={6}
              progress={true}
              count={statistics.patientTransfert.avg_rate}
              nameSerie="Taux de transfert des patients"
              data={statistics.patientTransfert.perDay.data}
              categories={statistics.patientTransfert.perDay.dates}
            />
          </div>

          <div className="lg:col-span-2 md:col-span-2 ms:col-span-3 xs:col-span-3">
            <MyCard
              title={
                "MOTIFS DE RÉFÉRENCEMENT VERS D'AUTRES STRUCTURES SELON LA CIM-11"
              }
              withCount={false}
              logo={motifIcon}
              typeChart={chart.SEO_REASONS}
              nameSerie="Nombre"
              data={statistics.countMotifsTransfert.map(
                (item) => item.total_count
              )}
              categories={statistics.countMotifsTransfert.map(
                (item) => item.grouped_parent_name
              )}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex col justify-center  bg-sky-500/50 p-20 pt-60">
          <Loading type="spin" size="large" />
        </div>
      )}
    </div>
  );
}

export default PatientsPlainTextComponent;
