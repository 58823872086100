import React from "react";
import Chart from "react-apexcharts";

interface IProps {
  nameSerie?: string;
  data: {
    statistics: {
      name: string;
      data: number[];
    }[];
    dates?: Date | string[];
  };
}

export default function Evolution({ nameSerie, data }: IProps) {
  const state: any = {
    series: data.statistics,
    options: {
      chart: {
        height: 350,
        stacked: true,
        sparkline: {
          enabled: true,
        },
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          columnWidth: "40%",
        },
      },
      responsive: [
        {
          breakpoint: 580,
          options: {
            width: "100%",
            height: 210,
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: data.dates,
      },
      fill: {
        opacity: 1,
      },
      colors: [
        "#19AEC3",
        "#94D13D",
        "#F56161",
        // "#0B2349",
        "#E29500",
        "#4482BE",
      ],
      legend: {
        position: "top",
        offsetX: 900,
        offsetY: 0,
      },
    },
  };

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        min-width={200}
        height={210}
      />
    </>
  );
}
